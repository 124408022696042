<template>
  <thead>
    <tr :class="props.styles.thead.tr">
      <th
        v-if="props.multiselect"
        :class="props.styles.thead.th"
      >
        <UiCheckbox
          :model-value="props.allRowsSelected"
          :value="props.allRowsSelected"
          size="small"
          variant="navy"
          :indeterminate="props.isCheckboxIndeterminate"
          :container-class="['justify-center']"
          @click="handleCheckboxChange"
        />
      </th>
      <template
        v-for="(header, index) in props.headers"
        :key="header.name || header.value?.toString()"
      >
        <UiBaseTableHeaderCell
          v-if="isHeaderVisible(header)"
          :column="header"
          :body="props.body"
          :column-index="index"
          :sort-field="props.sortField"
          :sort-order="props.sortOrder"
          :styles="props.styles"
          @click:column="emit('click:column', $event)"
        >
          <template
            v-for="(_, name) in $slots"
            #[name]="slotData"
          >
            <slot
              :name="name"
              v-bind="slotData"
            />
          </template>
        </UiBaseTableHeaderCell>
      </template>
    </tr>
  </thead>
</template>

<script setup lang="ts">
import UiCheckbox from '../UiForm/UiCheckbox/UiCheckbox.vue'
import UiBaseTableHeaderCell from './UiBaseTableHeaderCell.vue'
import type { Column, HeaderProps } from './UiBaseTable.types'

const props = withDefaults(defineProps<HeaderProps>(), {
  allRowsSelected: false,
  multiselect: false,
})

const emit = defineEmits<{
  (e: 'click:column', value: any)
  (e: 'click:checkbox')
}>()

function handleCheckboxChange() {
  emit('click:checkbox')
}

function isHeaderVisible(header: Column) {
  if (!header) {
    return false
  }
  if (!('visible' in header)) {
    return true
  }

  return header.visible
}
</script>
